<template>
  <div>
    <div class="page-title">
      <h3>Редактирование бригады</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/teams')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <section v-else>
      <form class="form" @submit.prevent="submitHandler">
        <div class="input-field">
          <input id="department" type="text" disabled="disabled" v-model="team.department.name"/>
          <label for="department">Отдел</label>
        </div>

        <table style="margin-bottom: 20px">
          <thead>
          <tr>
            <th>ФИО</th>
            <th>Водитель</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(record, idx) of members" :key="record.user.id">
            <td>
              {{ record.user.last_name }} {{ record.user.first_name }} {{ record.user.middle_name }}
            </td>
            <td>
              <label>
                <input :id="'driver_'.concat(record.user.id)" type="checkbox" class="filled-in blue darken-4"
                       v-model="record.driver"/>
                <span></span>
              </label>
            </td>
          </tr>
          </tbody>
        </table>

        <button class="btn waves-effect waves-light blue darken-4" type="submit">
          Сохранить
          <i class="material-icons right">send</i>
        </button>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: 'teamEdit',
  metaInfo() {
    return {
      title: this.$title('Редактирование бригады')
    }
  },
  data: () => ({
    loading: true,
    name: '',
    team: {},
    members: [],
  }),
  async mounted() {
    this.team = await this.$store.dispatch('fetchTeamById', this.$route.params.id)

    if (this.team) {
      this.members = await this.$store.dispatch('fetchTeamMembers', this.$route.params.id)
    }

    this.loading = false

    setTimeout(() => {
      M.updateTextFields()
    })
  },
  methods: {
    async submitHandler() {
      await this.$store.dispatch('updateTeamMembers', {
        teamId: this.team.id,
        members: {
          "members": this.members
        }
      })
      await this.$router.push('/teams')
    },
    destroyed() {
    }
  },
}
</script>
